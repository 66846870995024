import { sentenceGameData } from '../data/sentenceGameData';
import { TStorageLessons, TTopic } from '../../Game/types';
import React, { FC, PropsWithChildren, useState } from 'react';
import '../styles/menu.css';
import { LessonName } from './LessonName';
import { roundScore } from '../../../utils/roundScore';
import { MINIMUM_SCORE } from '../../../constants';
import { useIonViewDidEnter } from '@ionic/react';
import { H1Top } from '../../../components/H1';
import styled from 'styled-components';
import { isLessonNotReleasedYet } from '../utils/isLessonNotReleasedYet';
import { useRuntimeStore } from '../../../initApp';
import { getCurrentLessonNumber } from '../../../utils/getCurrentLessonNumber';
import { TBlockReason } from '../../../types';
import { useAccessToAllPermissions } from '../../../components/SubscribeModal/useAccessToAllPermissions';
import { Capacitor } from '@capacitor/core';

export const LessonList: FC<PropsWithChildren> = () => {
  const getStartedLessons = useRuntimeStore((s) => s.getStartedLessons);

  const { getIsSubscriptionValid } = useAccessToAllPermissions();

  const [startedLessons, setStartedLessons] = useState<TStorageLessons | null>(
    null
  );

  useIonViewDidEnter(() => {
    updateStat();
  }, []);

  async function updateStat() {
    const data = await getStartedLessons();
    setStartedLessons(data);
  }

  const getLink = (path: string) => {
    return `/learn/${path}`;
  };

  const isLessonBlockedBySubscription = (lessonNumber: number) => {
    // return false;
    if (lessonNumber <= 1) {
      return false;
    }

    return !getIsSubscriptionValid();

    // return !(isStoreSubscriptionActive() || isManualSubscriptionActive());
  };

  const getBlockReason = (
    topic: TTopic,
    lessonNumber: number
  ): TBlockReason | undefined => {
    if (import.meta.env.VITE_UNBLOCK_ALL_LESSONS === 'true') {
      return undefined;
    }

    if (isLessonBlockedBySubscription(lessonNumber)) {
      return 'subscription';
    }

    if (isLessonNotReleasedYet(lessonNumber)) {
      return 'notReleasedYet';
    }

    if (!startedLessons) {
      return 'internalError';
    }

    const startedLessonPath = startedLessons[topic.path];
    if (!startedLessonPath || startedLessonPath.blocked) {
      return 'prevLessonNotPassed';
    }

    return undefined;
  };

  const getScore = (topic: TTopic) => {
    if (
      !startedLessons ||
      !startedLessons[topic.path] ||
      startedLessons[topic.path].score < MINIMUM_SCORE
    ) {
      return MINIMUM_SCORE;
    }

    return roundScore(startedLessons[topic.path].score);
  };

  return (
    <>
      {sentenceGameData.map((dataItem, partIndex) => {
        const topics = dataItem.topics;
        if (Capacitor.getPlatform() !== 'ios' && partIndex > 2) {
          return null;
        }
        return (
          <SPart key={partIndex}>
            <H1Top>{dataItem.name}</H1Top>
            {topics.map((topic: TTopic) => {
              const lessonNumber = getCurrentLessonNumber(topic.path);

              return (
                <LessonName
                  key={topic.file}
                  link={getLink(topic.path)}
                  title={topic.name}
                  subtitle={topic.desc || ''}
                  score={getScore(topic)}
                  order={lessonNumber}
                  blockedReason={getBlockReason(topic, lessonNumber)}
                />
              );
            })}
          </SPart>
        );
      })}
    </>
  );
};

const SPart = styled.div`
  padding-bottom: 40px;
`;
