import { IonContent, IonPage } from '@ionic/react';
// @ts-ignore
import PolakoCatImage from '../../assets/images/whole-bottom.svg';
// @ts-ignore
import AppleSoon from '../../assets/images/appstore-download.svg';
// @ts-ignore
import GoogleSoon from '../../assets/images/googleplay-download.svg';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Signup } from './Signup';
import { Capacitor } from '@capacitor/core';

const useOpenStoreIfOpenedOnPhone = () => {
  if (!Capacitor.isNativePlatform()) {
    if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
      // @ts-ignore
      window.location =
        'https://apps.apple.com/dk/app/polako-%D1%82%D1%80%D0%B5%D0%BD%D0%B0%D0%B6%D0%B5%D1%80-%D1%81%D0%B5%D1%80%D0%B1%D1%81%D0%BA%D0%BE%D0%B3%D0%BE/id6467810104'; // Ссылка на ваше приложение в App Store
    }
  }
};

export const ReleaseWall = () => {
  useEffect(() => {
    useOpenStoreIfOpenedOnPhone();
  }, []);

  return (
    <IonPage>
      <IonContent fullscreen>
        <SFlex>
          <PolakoLogoStyled />

          <SStoreLink
            href="https://apps.apple.com/dk/app/polako-%D1%82%D1%80%D0%B5%D0%BD%D0%B0%D0%B6%D0%B5%D1%80-%D1%81%D0%B5%D1%80%D0%B1%D1%81%D0%BA%D0%BE%D0%B3%D0%BE/id6467810104"
            target="_blank"
          >
            <AppleSoon />
          </SStoreLink>

          <SStoreLink
            href="https://play.google.com/store/apps/details?id=app.polako"
            target="_blank"
          >
            <GoogleSoon />
          </SStoreLink>

          <Signup />

          <br />
        </SFlex>
      </IonContent>
    </IonPage>
  );
};

const SFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 40px 0;
`;

const SIcons = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
`;

const SStoreLink = styled.a`
  width: 40%;
  display: block;
  margin: 0 auto;
`;

const PolakoLogoStyled = styled(PolakoCatImage)`
  width: 50%;
  text-align: center;
  margin: 0 auto;
  display: block;
  margin-bottom: 40px;
  min-height: 200px;
`;
