export const PHRASES = [
  'Молодец!',
  'Отлично!',
  'Круто!',
  'Супер!',
  'Зачёт!',
  'Ты в ударе!',
  'Как профи!',
  'Браво!',
  'Это попадание!',
  'Класс!',
  'Ты гений!',
  'Сделано!',
  'Ну даешь!',
  'Ну супер вообще!',
  'Красиво!',
  'Кучеряво!',
  'Топово вообще',
  'Огонь жара',
  'Ну пушка',
  'Эко вон как решил, а!',
  'Угадал смотри-ка!',
  'Ну ты серб!',
  'Ну все, выучил сербский!',
  'Знаешь язык как нейтив',
  'Ну ты знаток',
  'А ты точно не серб?',
  'Откуда ты знал?',
  'Ты наверное отличником был?',
  'С золотой медалью школу кончал?',
  'Полиглот!',
  'Огонь',
  'Верно!',
  'Ты глянь-ка',
  'Я вылез тебя похвалить',
  'Ну вообще',
  'Я валяюсь!',
  'Отпад!',
  'Вижу тебе нравится',
  'Супер!',
  'Талант',
  'Хитро!',
  'Сила!',
  'Браво!',
  'Это было круто!',
  'Ты меня удивляешь!',
  'Одлично!',
  'На правильном пути!',
  'Ты сегодня в форме!',
  'Заслуженно!',
  'Добро!',
  'Ты звезда!',
  'Отличный ход!',
  'На уровне!',
  'Зашибись!',
  'Ты — бомба!',
  'Блин, красавчик!',
  'Мастер-класс!',
  'Сильно, король!',
  'Зачётно!',
  'Быстро и верно!',
  'Эй, топчик!',
  'Профи, без вариантов!',
  'Лайк э босс!',
  'Ты — чемпион!',
  'Классно это ты!',
  'Я поражен. Молодец!',
  'Ну здорово!',
  'Нe ожидал такой скорости!',
];
