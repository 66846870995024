import { useRuntimeStore } from '../../initApp';
import React from 'react';
import { Clipboard } from '@capacitor/clipboard';
import { useIonViewWillEnter } from '@ionic/react';
import { SignedInButtons } from './SignedInButtons';
import { Button } from '../../components/Button';
import { useAuth } from '../../hooks/useAuth';
import { SignInButton } from '../Signup/SignInButton';
import { logoApple, logoGoogle } from 'ionicons/icons';
import styled from 'styled-components';
import { Tumbler } from '../../components/RadioButton/Tumbler';
import { LabeledInput } from '../../components/LabeledInput/LabeledInput';
import { TimeInput } from '../../components/TimeInput';
import { useLocalNotifications } from '../../components/NotificationsSetup/useLocalNotifications';

import { Typo } from '../../components/Typo';
import { useShowCatForMsNew } from '../Game/components/EncouragingCat2/useShowCatForMsNew';
import { SubscriptionBlock } from './SubscriptionBlock';

import { isDevelopmentFeaturesEnabled } from '../../utils/isDevelopmentFeaturesEnabled';
import { useRestorePurchases } from './useRestorePurchases';
import { Capacitor } from '@capacitor/core';
// import { SubscriptionModal } from '../../components/SubscribeModal/SubscriptionModal';

export const Settings = () => {
  const { user } = useRuntimeStore();

  const { linkWithSocialAccount, signIn, removeAnonymousAccount } = useAuth();

  const [haveAccount, setHaveAccount] = React.useState(false);

  const { showCat } = useShowCatForMsNew();
  const { restorePurchases } = useRestorePurchases();

  const {
    time,
    setTime,
    setRepeatedNotificationsTime,
    initNotificationsOnSettingsPage,
    onNotificationsCheckboxChange,
    isNotificationsOn,
  } = useLocalNotifications();

  useIonViewWillEnter(() => {
    initNotificationsOnSettingsPage();
  }, []);

  const logInOrSignUp = (strategy: 'apple' | 'google') => {
    if (haveAccount) {
      signIn(strategy, true);
    } else {
      linkWithSocialAccount(strategy);
    }
  };

  const tgText = `MyID: ${user?.uid}`;

  return (
    <SSettingsWrapper>
      <SInner>
        <div>
          <STimeSettingLine>
            <LabeledInput label="Ежедневное напоминание о желании учить сербский">
              <Tumbler
                checked={isNotificationsOn}
                onChange={onNotificationsCheckboxChange}
              />
            </LabeledInput>
            <STimeInput disabled={!isNotificationsOn}>
              <TimeInput
                time={time}
                setTime={setTime}
                isDisabled={!isNotificationsOn}
                confirmSelectedTimeHandler={setRepeatedNotificationsTime}
              />
            </STimeInput>
          </STimeSettingLine>

          <br />
          <br />

          {Capacitor.getPlatform() === 'ios' && <SubscriptionBlock />}

          {/*<SettingsDonationBlock title={'Донат'}>*/}
          {/*  <DonationContent />*/}
          {/*</SettingsDonationBlock>*/}
        </div>

        <br />
        <br />

        <SSignInButtons>
          {user?.isAnonymous ? (
            <>
              <STopBottomFlex>
                <SFlex>
                  {Capacitor.getPlatform() === 'ios' && (
                    <SignInButton
                      iconSrc={logoApple}
                      onClick={() => {
                        logInOrSignUp('apple');
                      }}
                      text={
                        haveAccount
                          ? 'Войти через эппл'
                          : 'Зарегистрироваться через эппл'
                      }
                    />
                  )}
                  <SignInButton
                    iconSrc={logoGoogle}
                    onClick={() => logInOrSignUp('google')}
                    text={
                      haveAccount
                        ? 'Войти через гугл'
                        : 'Зарегистрироваться через гугл'
                    }
                  />
                  <SLink onClick={() => setHaveAccount(!haveAccount)}>
                    {!haveAccount ? 'У меня есть акаунт' : 'У меня нет акаунта'}
                  </SLink>
                </SFlex>
                <div>
                  <Button
                    square={false}
                    text={'Выйти из демо режима'}
                    onClick={removeAnonymousAccount}
                    size={'m'}
                  ></Button>
                </div>
              </STopBottomFlex>
            </>
          ) : (
            <SignedInButtons />
          )}
        </SSignInButtons>
      </SInner>
      <STerms>
        <SBottomLeft>
          <div>
            <a href="https://polako.app/terms">Terms & Conditions</a>
          </div>
          <br />
          <div>
            <a href="https://polako.app/privacy-policy">Privacy Policy</a>
          </div>
          <br />

          <span>
            <span
              onClick={async () => {
                try {
                  await Clipboard.write({
                    string: user?.uid,
                  });
                  showCat({
                    text: 'Скопировал!',
                  });
                } catch (e) {
                  showCat({
                    text: 'Не получилось скопировать(',
                    textSub: 'Скопируй плз вручную',
                  });
                }
              }}
            >
              Ваш ID: {user?.uid}
            </span>
            <br />
            <br />

            <ButtonCopy
              size={'s'}
              text={'Скопировать ваш ID'}
              onClick={async () => {
                try {
                  await Clipboard.write({
                    string: user?.uid,
                  });
                  showCat({
                    text: 'Скопировал!',
                  });
                } catch (e) {
                  showCat({
                    text: 'Не получилось скопировать(',
                    textSub: 'Скопируй плз вручную',
                  });
                }
              }}
              square={false}
            />
            <br />
            <br />
            <ButtonCopy
              onClick={async () => {
                window.open('https://t.me/polakoapp?text=' + tgText);
              }}
              size={'s'}
              text={'Отправить ID в телеграм разработчику'}
              square={false}
            />
            {/*<a*/}
            {/*  target="_blank"*/}
            {/*  href={'https://t.me/polakoapp?text=' + tgText}*/}
            {/*  rel="noreferrer"*/}
            {/*>*/}
            {/*  Отправить мне ID в телеграм*/}
            {/*</a>*/}
          </span>
        </SBottomLeft>
        {Capacitor.getPlatform() === 'ios' && (
          <div>
            <Typo.LinkButton onClick={restorePurchases}>
              Восстановить покупки
            </Typo.LinkButton>
            <br />
            <br />
            {
              <div>
                {isDevelopmentFeaturesEnabled() ? 'development' : 'production'}
              </div>
            }
          </div>
        )}
      </STerms>
    </SSettingsWrapper>
  );
};

const SBottomLeft = styled.div`
  flex: 1;
`;

const ButtonCopy = styled(Button)`
  &::part(native) {
    padding: 3px;
  }
`;

const SFlex = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding-bottom: 10px;
`;

const SInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

const STopBottomFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

const SSignInButtons = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const STerms = styled.div`
  font-weight: 700;
  font-size: 8px;
  padding-bottom: 20px;

  display: flex;
  justify-content: space-between;
`;

const SSettingsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const STimeSettingLine = styled.div`
  display: flex;
  gap: 10px;
`;

const STimeInput = styled.div<{ disabled: boolean }>`
  display: flex;
  opacity: ${(props) => (props.disabled ? 0.15 : 1)};
`;

const SLink = styled.div`
  text-decoration: underline;
  color: white;
  font-size: 14px;
  cursor: pointer;
  font-weight: 900;
`;
