import { useRuntimeStore } from '../../initApp';
import { Button } from '../../components/Button';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import React from 'react';

import { IonAlert, useIonViewDidEnter } from '@ionic/react';
import styled from 'styled-components';
import { Analytics } from '../../analytics';
import { useAuth } from '../../hooks/useAuth';
import { useShowCatForMsNew } from '../Game/components/EncouragingCat2/useShowCatForMsNew';

export const SignedInButtons = () => {
  const { user, setUser } = useRuntimeStore();
  const { logOut } = useAuth();
  const { showCat } = useShowCatForMsNew();

  useIonViewDidEnter(() => {
    Analytics.setCurrentScreen('Settings');
  }, []);

  return (
    <>
      <SFlex>
        <IonAlert
          header="Вы уверены, что хотите удалить акаунт?"
          trigger="delete-account"
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
                showCat({ text: 'Уф, я перепугался' });
              },
            },
            {
              text: 'OK',
              role: 'confirm',
              handler: async () => {
                try {
                  await FirebaseAuthentication.deleteUser();
                } catch (e) {
                  console.error(e);
                  showCat({
                    text: 'Сорри, надо разлогиниться, снова зайти и удалить',
                  });
                  return;
                }

                setUser(null);
                showCat({ text: 'Жаль ;(' });
              },
            },
          ]}
          onDidDismiss={({ detail }) =>
            console.log(`Dismissed with role: ${detail.role}`)
          }
        ></IonAlert>
        <Button
          square={false}
          text={'Выйти из акаунта'}
          onClick={logOut}
          size={'m'}
          wide={true}
          fontSize={12}
        ></Button>

        <Button
          square={false}
          text={'Удалить акаунт'}
          id={'delete-account'}
          size={'m'}
          fontSize={12}
          wide={true}
          color={'red'}
        ></Button>
      </SFlex>
    </>
  );
};

const SFlex = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  margin-bottom: 20px;
`;
