import React from 'react';
import TextWithSubLogo from '../../assets/images/text-with-sub.svg';
import CatFace from '../../assets/images/cat-face.svg';
import { PageWrapper } from '../../components/PageWrapper';
import styled from 'styled-components';
import { SPaddingsWrapperStyled } from '../Intro/styles';
import { logoApple, logoGoogle } from 'ionicons/icons';
import { SignInButton } from './SignInButton';
import { useAuth } from '../../hooks/useAuth';
import { Capacitor } from '@capacitor/core';

export function Signup() {
  const { signIn } = useAuth();

  return (
    <PageWrapper>
      <SPaddingsWrapperStyled>
        <SContent1>
          <SLogoWrapper>
            <TextWithSubLogo />
          </SLogoWrapper>
        </SContent1>

        <SContent2>
          <CatFace></CatFace>
        </SContent2>

        <SContent3>
          {Capacitor.getPlatform() === 'ios' && (
            <SignInButton
              iconSrc={logoApple}
              onClick={() => signIn('apple')}
              text={'Залогиниться через эппл'}
            />
          )}

          <SignInButton
            iconSrc={logoGoogle}
            onClick={() => signIn('google')}
            text={'Залогиниться через гугл'}
          />

          <br />

          <SLink onClick={() => signIn('anonymous')}>
            Попробовать без регистрации
          </SLink>
        </SContent3>
      </SPaddingsWrapperStyled>
    </PageWrapper>
  );
}

const SLogoWrapper = styled.div`
  width: 100%;
`;

const SContent1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  font-size: 18px;
  font-weight: 900;
  text-align: center;
`;

const SContent2 = styled.div`
  width: 100%;
  flex: 1;
  height: 100%;
`;

const SContent3 = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding-bottom: 30px;
  flex-direction: column;
  align-items: center;
`;

const SLink = styled.div`
  text-decoration: underline;
  color: white;
  font-size: 10px;
  cursor: pointer;
  font-weight: 900;
`;
