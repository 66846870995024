import {
  ASK_FOR_NOTIFICATIONS_AFTER_SOLVED,
  LESSON_CONSIDERED_PASSED,
} from '../../../constants';
import { useRuntimeStore } from '../../../initApp';
import { useLessonStat } from './store/useLessonStat';
import { useEffect, useState } from 'react';
import { TGetLesson, TTask, TTopic } from '../types';
import { LocalNotifications } from '@capacitor/local-notifications';
import { useShowCatForMsNew } from '../components/EncouragingCat2/useShowCatForMsNew';

let audio: HTMLAudioElement;

type TProps = {
  topic: TTopic;
  currentTask: TTask | null;
  getUserTypedStr: () => string;
  getFilledSentenceStr: () => string;
  getLesson: TGetLesson;
  mistakeHandler: () => void;
  handleStreakAnswer: (isCorrect: boolean) => void;
};

export const useReadyButtonHandler = ({
  topic,
  currentTask,
  getUserTypedStr,
  getFilledSentenceStr,
  getLesson,
  mistakeHandler,
  handleStreakAnswer,
}: TProps) => {
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);

  const { showCat } = useShowCatForMsNew();

  const {
    setPassedItems,
    incrementCorrect,
    incrementScore,
    incrementWrong,
    decrementScore,
    unblockNextLesson,
    saveVisitDate,
    currentSessionLessonsPassed,
    incCurrentSessionLessonsPassed,
    getIsNotificationsSetAlready,
    setIsNotificationsWindowOpened,
  } = useRuntimeStore();

  useEffect(() => {
    if (!currentTask) return;
    audio = new Audio(`/assets/sounds/${currentTask.id}.mp3`);
  }, [currentTask]);

  const {
    lessonStat,
    incrementCorrect: incrementCorrectLocal,
    incrementWrong: incrementWrongLocal,
    incrementScore: incrementScoreLocal,
    decrementScore: decrementScoreLocal,
  } = useLessonStat({ getLesson, topic });

  const isUserGuessedRight = () => getUserTypedStr() === getFilledSentenceStr();

  const stopSound = () => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }
  };

  const playSound = async () => {
    stopSound();
    await audio.play();
  };

  const tryToUnblockNextLesson = async () => {
    const newScore = incrementScoreLocal();

    if (newScore && newScore >= LESSON_CONSIDERED_PASSED) {
      const isUnblocked = await unblockNextLesson(topic.path);

      if (isUnblocked) {
        showCat({
          text: 'Super je! Следующий урок разблокирован!',
          duration: 3000,
        });
      }
    }
  };

  const tryToSetUpNotifications = async () => {
    const permissions = await LocalNotifications.checkPermissions();

    if (permissions.display !== 'prompt') {
      return;
    }

    if (await getIsNotificationsSetAlready()) {
      return;
    }

    if (currentSessionLessonsPassed === ASK_FOR_NOTIFICATIONS_AFTER_SOLVED) {
      setIsNotificationsWindowOpened(true);
    }

    if (currentSessionLessonsPassed > ASK_FOR_NOTIFICATIONS_AFTER_SOLVED) {
      return;
    }

    incCurrentSessionLessonsPassed();
  };

  const correctAnswerHandler = async () => {
    playSound();
    handleStreakAnswer(true);
    incrementCorrect(topic);
    incrementCorrectLocal();
    incrementScore(topic);
    tryToUnblockNextLesson();
    tryToSetUpNotifications();
    saveVisitDate();

    setPassedItems(currentTask?.id, topic);
    setIsCorrect(true);
  };

  const incorrectAnswerHandler = async () => {
    setIsCorrect(false);
    handleStreakAnswer(false);
    incrementWrong(topic);
    incrementWrongLocal();

    decrementScore(topic);
    decrementScoreLocal();

    // Сначала показываем что человек ошибся, потом через 600мс сбрасываем
    setIsCorrect(false);
    setTimeout(() => mistakeHandler(), 600); // не трогай этот код, он нужен
  };

  const readyClickHandler = async () => {
    if (!currentTask) return;

    // TODO: !!! тут надо все переводить в lower case, trim, убирать знаки препинания. См уже готовую функцию где-то
    if (isUserGuessedRight()) {
      await correctAnswerHandler();
    } else {
      await incorrectAnswerHandler();
    }
  };

  return {
    readyClickHandler,
    lessonStat,
    isCorrect,
    setIsCorrect,
    playSound,
  };
};
