import React from 'react';
import { Modal } from '../../../components/Modal/Modal';
import { SignInButton } from '../../Signup/SignInButton';
import { logoApple, logoGoogle } from 'ionicons/icons';
import styled from 'styled-components';
import { useAuth } from '../../../hooks/useAuth';
import { Capacitor } from '@capacitor/core';

type TProps = {
  onSuccess(): void;
  close(): void;
};

export function GameSignIn({ onSuccess, close }: TProps) {
  const { linkWithSocialAccount } = useAuth();

  return (
    <Modal close={close}>
      <h2>Надеюсь, тебе понравилось!</h2>
      <p>Чтобы продолжить, надо зарегистрироваться</p>
      <p>Это супер быстро и просто!</p>
      <br />
      <SFlex>
        {Capacitor.getPlatform() === 'ios' && (
          <SignInButton
            iconSrc={logoApple}
            onClick={() => linkWithSocialAccount('apple', onSuccess)}
            text={'Через эппл'}
          />
        )}

        <SignInButton
          iconSrc={logoGoogle}
          onClick={() => linkWithSocialAccount('google', onSuccess)}
          text={'Через гугл'}
        />
      </SFlex>
    </Modal>
  );
}

const SFlex = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: column;
  padding-bottom: 10px;
`;
